body, html {
  margin: 0;
  padding: 0;
  background-color: #f4f4f4; /* Softer background color */
  color: #333;
  font-family: Arial, sans-serif;
}

button {
  background-color: #444;
  color: #fff;
  border: none;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
}

input, textarea {
  background-color: #fafafa;
  color: #333;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
}

.App {
  margin-left: 220px;
  padding: 2rem;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 220px;
  background-color: #2e3b4e;
  overflow-y: auto;
}

.navbar-title {
  font-size: 1.5em;
  text-align: center;
  padding: 1rem;
}

.nav-item {
  display: block;
  padding: 1rem;
  text-decoration: none;
  color: #fff;
}

.nav-item.active {
  background-color: #3c4d61;
}
