.navbar {
  background-color: #2e3b4e;
  height: 100vh;
  width: 200px;
  position: fixed;
  padding: 1rem;
  top: 0;
  left: 0;
}

.navbar-header {
  color: #fff;
  margin-bottom: 1rem;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
}

.nav-item {
  margin: 1rem 0;
}

.nav-item a {
  color: #fff;
  text-decoration: none;
  font-size: 1.2rem;
}

.nav-item a.active {
  border-left: 4px solid #4caf50;
}
