.loginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.loginForm {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
