.blog-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
  padding: 20px;
}

.blog-item {
  padding: 20px;
  border-radius: 10px;
  background-color: #f9f9f9; /* Slightly off-white background */
  display: flex;             /* Add this line */
  flex-direction: column;    /* Add this line */
}

.delete-button {
  cursor: pointer;
  margin-top: auto;          /* Add this line */
  width: 60px;
}

.blog-title {
  color: blue;
}
