.create-blog-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  background-color: #f9f9f9; /* Slightly off-white background */
  padding: 30px;
  border-radius: 10px;
}
