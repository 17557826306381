.blog-content {
  margin: 20px;
  padding: 20px;
  border-radius: 10px;
  background-color: #f9f9f9; /* Slightly off-white background */
}

.blog-title {
  font-size: 24px;
  font-weight: bold;
}
